import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Settings`}</h1>
    <p>{`Setting window allows for you to change specific setup and default values of Response Time Track.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/41bc5230a0b6a3cb1445ff75777ee6f5/ec605/setting_nav.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "195.00000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAnABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBAH/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAe/3chzuttNag8mqog02MX//xAAbEAACAgMBAAAAAAAAAAAAAAAAARITAgMgIf/aAAgBAQABBQKlFOItC6XEifsj/8QAGREAAgMBAAAAAAAAAAAAAAAAAhAAARIT/9oACAEDAQE/AcDOYu1//8QAGREAAgMBAAAAAAAAAAAAAAAAAhAAARIT/9oACAECAQE/AdlOhOl//8QAGBABAQADAAAAAAAAAAAAAAAAAEEQMDH/2gAIAQEABj8CdVExdP8A/8QAHxAAAgEEAgMAAAAAAAAAAAAAAAERIUFhcaHRIIGR/9oACAEBAAE/IY7v6jM4G1Vo9jiegotwF6Kz1K5EaUS34aDZDQ//2gAMAwEAAgADAAAAEFgPDH/P/8QAGBEAAwEBAAAAAAAAAAAAAAAAARBhABH/2gAIAQMBAT8QloM+Bf/EABgRAAMBAQAAAAAAAAAAAAAAAAEQYQAR/9oACAECAQE/ELajHpX/xAAkEAACAgECBQUAAAAAAAAAAAABEQAhMUGBIFFhcZGhsdHh8P/aAAgBAQABPxBgonf4oDfh7QFOuAAK9IT2c7ZuGTfqUVCewQ3XnoCmFttAEihqRZ4HvlxeYUBBApeIG+0//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Settings Navigation",
            "title": "Settings Navigation",
            "src": "/static/41bc5230a0b6a3cb1445ff75777ee6f5/09b79/setting_nav.jpg",
            "srcSet": ["/static/41bc5230a0b6a3cb1445ff75777ee6f5/59e87/setting_nav.jpg 60w", "/static/41bc5230a0b6a3cb1445ff75777ee6f5/e439a/setting_nav.jpg 120w", "/static/41bc5230a0b6a3cb1445ff75777ee6f5/09b79/setting_nav.jpg 240w", "/static/41bc5230a0b6a3cb1445ff75777ee6f5/158ba/setting_nav.jpg 360w", "/static/41bc5230a0b6a3cb1445ff75777ee6f5/7cc5e/setting_nav.jpg 480w", "/static/41bc5230a0b6a3cb1445ff75777ee6f5/ec605/setting_nav.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Setting Items`}</h2>
    <h3>{`Location Tracking`}</h3>
    <p>{`Location tracking is used to calculate the mileage traveled during a rescue time.
This feature only displays for premium Response Time Trackers.`}</p>
    <h3>{`History Information Layout`}</h3>
    <p>{`History information layout determines the layout on first load into history window. The options are `}<inlineCode parentName="p">{`card`}</inlineCode>{` and `}<inlineCode parentName="p">{`grid`}</inlineCode>{`.`}</p>
    <h3>{`CSV File Name`}</h3>
    <p>{`When exporting csv file, the default name of CSV will have whatever text in settings added with the current date and time.`}</p>
    <h3>{`Filter Record Duration displays`}</h3>
    <p>{`Filter record duration displays is the default amount of days from today to filter rescue times in the history window.`}</p>
    <h3>{`Purchase subscription`}</h3>
    <p>{`For those who are not a premium Response Time Tracker, there is a place to purchase a subscription for no ads, cloud storage, and location tracking.`}</p>
    <h3>{`Enable Notes Entry`}</h3>
    <p>{`Enable notes entry will display a text box to add a note to rescue time at the end of tracking rescue time in time window.`}</p>
    <h3>{`Delete Account`}</h3>
    <p>{`Delete account will only display for those who are login. Delete account will delete account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      